$(document).ready(function(){
	$(window).load(function(){
		mod();
		slideto();
		clickVideoFP();
		showreelSlick();
		singleShowreelSlick();
		btsSlick();
		clientSlick();
		play();

			$('.slider-for').slick({
				  slidesToShow: 1,
				   // centerMode: true,
				  slidesToScroll: 1,
				  asNavFor: '.slider-nav',
				  variableWidth: true,
				  swipeToSlide: false,
				  accessibility: false,
				  arrows: true,
				  infinite: true,
				  nextArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"></button>',
				  prevArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"></button>',
			});
			$('.slider-nav').slick({
				  slidesToShow: 4,
				  slidesToScroll: 1,
				  asNavFor: '.slider-for',
				  dots: false,
				  nextArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"></button>',
				  prevArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"></button>',
				  focusOnSelect: false,
				  autoplay: false,
				  responsive: [
				   	{
				      breakpoint: 992,
				      settings: {
				        slidesToShow: 3,
				      }
				    },
					 {
				      breakpoint: 768,
				      settings: {
				        slidesToShow: 2,
				      }
				    },
				    {
				      breakpoint: 480,
				      settings: {
				        slidesToShow: 2,
				      }
				    }
				]
			});

			$('.slider-for').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			  //console.log('beforeChange', currentSlide, nextSlide);
			  // $(".slick-current").removeClass("high-light");
			  // $(".slick-current").addClass("normal");
			  //console.log($( ".slick-slide[data-slick-index='"+nextSlide+"']" ));
			  var nextSlick = $( ".slick-slide[data-slick-index='"+nextSlide+"']" );
			  // nextSlick.addClass("high-light");
			  // nextSlick.removeClass("normal");
			});

			$('.slider-for').on('afterChange', function(event, slick, currentSlide){
			  //console.log('afterChange', currentSlide);
			  //$(".slick-current").addClass("high-light");
			  //$(".slick-current").removeClass("normal");
			});
			setTimeout(()=>{
			  //console.log($(".slick-current"));
			  // $(".slick-current").removeClass("normal");
			  // $(".slick-current").addClass("high-light");
			  //$('.variable-width').slick('setPosition');
			}, 100);
		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		$('.menu-container .menu-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});

		$(document).on('click', '.mobile-menu.notAnimated', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$(this).removeClass('notAnimated').addClass('animated');
			$('.menu-container').addClass('show');
			setTimeout(function(){
				$('.menu-container').addClass('animate');
			}, 500);
		});

		$(document).on('click', '.mobile-menu.animated', function(){
			$(this).removeClass('animated').addClass('notAnimated');

			$('.menu-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.menu-container').removeClass('show');
			}, 500);

			setTimeout(function(){
				$('.menu-container .menu-contain nav ul li .sub-menu').removeClass('show');
			}, 700);

		});

		$(document).on('click', '.submenu-button', function(){
			$(this).parent('li').find(' > .sub-menu').addClass('show');
		});
		$(document).on('click', '.submenu-close-button', function(){
			$(this).parent('li').parent('ul').removeClass('show');
		});

		$(document).on('click', 'a[href^="mailto:"].main', function(e){
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function(){
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function(){
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.mailto-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '.search-btn', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function(){
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function(){
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function(){
				$('.search-form-template').removeClass('animate');
			}, 300);
		});

		$(document).on('click', '.testimonial-item-contain', function(){
			$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_testimonial',
					id : $(this).data('id')
				},
				beforeSend: function(){
					$('body, html').css({
						'overflow' : 'hidden'
					});
					$('.popup-modal-container').addClass('show');
					$('html').addClass('popupshow');
				},
				success: function(response) {
					
					setTimeout(function(){
						$('.popup-modal-container').addClass('animate');
					}, 300);
					$('.popup-modal-container .popup-body').html(response.content);
			
				}
			});
		});

		$(document).on('click', '.popup-close', function(){
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.popup-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function(){
				$('.popup-modal-container').removeClass('animate');
			}, 300);
		});


		$(document).on('click', '.apply-btn', function(e){
			var position = $(this).data('position');
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.career-container .yourPosition input').val(position);
			$('.career-container').addClass('show');
			setTimeout(function(){
				$('.career-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.career-close-button', function(){
			$('.career-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.career-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '#resumeBtn', function(){
			var input = $('.resume-container .resume input');
			input.click();
		});

		$('.resume-container .resume input').change(function(e){
			var FileName = e.target.files[0];
			$('#resumeBtn').addClass('active').text(FileName.name);
		});

		$(document).on('click', '.expand-term', function(e){
			e.preventDefault();
			$(this).toggleClass('fa-angle-down').toggleClass('fa-angle-up');
			$(this).parent('a').parent('.parent-term').find('.chid-term').first().toggleClass('active');
		});

		$(document).on('click', '.expand-selection-term', function(e){
			$('.archive-sidebar .term-container').toggleClass('active');
		});

		$(document).on('click', '.parent-backdrop .backdrop', function(){
			$('body, html').removeAttr('style');
			$(this).parent('.parent-backdrop').removeClass('show');
			setTimeout(function(){
				$(this).parent('.parent-backdrop').removeClass('animate');
			}, 300);
		});

	});
});

function showreelSlick() {
	$('.showreel-slider-wrapper').slick({
	    dots: false,
	    infinite: true,
	    slidesToShow: 3,
	    slidesToScroll: 1,
	    arrows: true,
	    nextArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"></button>',
		prevArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"></button>',
		responsive: [
			 {
		      breakpoint: 768,
		      settings: {
		        slidesToShow: 2,
		        dots: true,
		        arrows: false
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		      }
		    }
		]
	});
}

function singleShowreelSlick() {
	$('.showreel-single-slider-wrapper').slick({
	    dots: false,
	    centerMode: true,
	    infinite: true,
	    slidesToShow: 3,
	    slidesToScroll: 1,
	    arrows: true,
	    nextArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"></button>',
		prevArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"></button>',
		responsive: [
			 {
		      breakpoint: 768,
		      settings: {
		        slidesToShow: 2,
		        dots: true,
		        arrows: false
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		      }
		    }
		]
	});
}

function btsSlick() {
	$('.bts-slider-wrapper').slick({
	    dots: false,
	    infinite: true,
	    slidesToShow: 3,
	    slidesToScroll: 1,
	    arrows: true,
	    nextArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"></button>',
		prevArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"></button>',
		responsive: [
			 {
		      breakpoint: 768,
		      settings: {
		        slidesToShow: 2,
		        dots: true,
		        arrows: false
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		      }
		    }
		]
    });
}

function clientSlick() {
	$('.client-slider-wrapper').slick({
	    dots: false,
	    infinite: true,
	    slidesToShow: 6,
	    slidesToScroll: 1,
	    arrows: true,
	    nextArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"></button>',
		prevArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"></button>',
		responsive: [
			 {
		      breakpoint: 768,
		      settings: {
		        slidesToShow: 5,
		        dots: true,
		        arrows: false
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 3,
		      }
		    }
		]
    });
}

// function modalSlick() {
// 	$('.loader-overlay').fadeIn('fast');   
// 	setTimeout(function () {

//     // DITO YUN SLIDER MO
//    	$('.slider-for').slick({
// 		  slidesToShow: 1,
// 		  slidesToScroll: 1,
// 		  adaptiveHeight: false,
// 		  asNavFor: '.slider-nav',
// 		  arrows: true,
// 		  nextArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"></button>',
// 		  prevArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"></button>',
// 		  fade: false,
// 		  responsive: [
// 		    {
// 		    	breakpoint: 992,
// 			    settings: {
// 			    	arrows: false,
// 			        adaptiveHeight: true,
// 			    }
// 		    }
// 		  ]
// 	});
// 	$('.slider-nav').slick({
// 		  slidesToShow: 4,
// 		  slidesToScroll: 1,
// 		  asNavFor: '.slider-for',
// 		  dots: false,
// 		  nextArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"></button>',
// 		  prevArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"></button>',
// 		  focusOnSelect: true,
// 		  responsive: [
// 		   	{
// 		      breakpoint: 992,
// 		      settings: {
// 		        slidesToShow: 3,
// 		      }
// 		    },
// 			 {
// 		      breakpoint: 768,
// 		      settings: {
// 		        slidesToShow: 2,
// 		      }
// 		    },
// 		    {
// 		      breakpoint: 480,
// 		      settings: {
// 		        slidesToShow: 2,
// 		      }
// 		    }
// 		]
// 	});

//     // END
// 	}, 200);
// 	$('.loader-overlay').fadeOut('fast');
// }

function mod() {
	// $('.taxonomy-section-flex .flex-wrapper .info-wrapper a').on('click', function(e){
		//prevent slick from beeing called multiple time
	$('a[data-slide]').on('click', function(e){
		$('.loader-overlay').fadeIn();
		if($('.slick-slider').hasClass('slick-initialized')) {
			} else {
				var slideto = $(this).data('id');
				modalSlick(slideto);
			}
			$('.loader-overlay').fadeOut();
		})
}
function slideto() {
	$('a[data-slide]').on('click', function(e){
		e.preventDefault();
		var slideto = $(this).data('slide');
		$('.slider-nav').slick('slickGoTo', slideto);
	})
}


function play() {
	$('.remodal').on('opened', function(){
		$('.slick-active').children('video').trigger('play');
		$('.slider-for').on('beforeChange', function(event, slick, currentSlide, nextSlide){    
		  	$('video').each(function(){
		  		$('.slick-active').children('video').trigger('pause');
		   
		  		});
		});
		$('.slider-for').on('afterChange', function(event, slick, currentSlide, nextSlide){    
		  	$('video').each(function(){
		  		$('.slick-active').children('video').trigger('play');
		  		});
		});
		$('.slider-nav .slick-slide').eq(0).addClass('slick-current');	
	})
	$('.remodal').on('closed', function(){
		$('.slick-slide').children('video').trigger('pause');
	})
}

function clickVideoFP() {
		$('.featured-video video').on('click', function(){
			$(this).trigger('play');
		if ($(this).hasClass('play')){
			$(this).removeClass('play');
			$(this).trigger('pause');
			$(this).addClass('pause');
		} else if ($(this).hasClass('pause')) {
			$(this).removeClass('pause');
			$(this).trigger('play');
			$(this).addClass('play');
		}
	});

}

