$(document).ready(function(){
	$(window).load(function(){

		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		headerPushTop();
		
		$('.main-panel').css({
			'min-height': $pageHeight + 4,
			'margin-bottom': $quickLinks,
			'padding-bottom': $footerHeight
		});

		$('.back-to-top').hide();
		
		$('.back-to-top').css({
			'bottom' : $quickLinks + 35
		});

		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('.back-to-top').fadeIn();
			} else {
				$('.back-to-top').fadeOut();
			}
		});
		$('.back-to-top a, .mobile-back-to-top a').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			return false;
		});

		$('.loader-overlay').fadeOut(200);

		$('.mailto-container .mailto-contain, .career-container .career-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});
		$(window).scroll(function () {
			if( $('header.site-header').hasClass('push-top') ) {
				$('.mailto-container .mailto-contain, .career-container .career-contain').css({
					'padding-bottom' : $quickLinks,
					'padding-top' : 0
				});
			} else {
				$('.mailto-container .mailto-contain, .career-container .career-contain').css({
					'padding-bottom' : $quickLinks,
					'padding-top' : $navHeight
				});
			}
		});

		// Sub Menus
		$('.menu-container .menu-contain nav ul li.menu-item-has-children').each(function(){
			$(this).append('<button type="button" class="submenu-button"><i class="fas fa-angle-right"></i></button>');
		});
		$('.menu-container .menu-contain nav ul li .sub-menu').each(function(){
			$(this).prepend('<li><button type="button" class="submenu-close-button"><i class="fas fa-angle-left"></i>Back</button></li>');
		});

	});
});

function headerPushTop(){
	var doc = document.documentElement;
  var w = window;

  var prevScroll = w.scrollY || doc.scrollTop;
  var curScroll;
  var direction = 0;
  var prevDirection = 0;

  var header = $('header.site-header');

  var checkScroll = function() {

    /*
    ** Find the direction of scroll
    ** 0 - initial, 1 - up, 2 - down
    */

    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) { 
      //scrolled up
      direction = 2;
    }
    else if (curScroll < prevScroll) { 
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }
    
    prevScroll = curScroll;
  };

  var toggleHeader = function(direction, curScroll) {
    if (direction === 2 && curScroll > 52) {
      header.addClass('push-top');
      prevDirection = direction;
    }
    else if (direction === 1) {
      header.removeClass('push-top');
      prevDirection = direction;
    }
  };
  
  window.addEventListener('scroll', checkScroll);
}